<template>
<div class="FooterLayout">
  
  <div class="FooterLayout__wrapper_upper">
    <div class="FooterLayout__copy">
      {{ `&copy; The Functional` }}<br/>{{ `Gut Group ${moment().format('YYYY')}` }}
    </div>
    
    <div class="FooterLayout__social-wrap">
      <div
        class="FooterLayout__social"
        @click="goTo('https://www.facebook.com/FunctionalGutClinic/')"
      >
        <img
          class="FooterLayout__social-icon"
          src="@/assets/img/facebook.svg"
        />
      </div>
      <div
        @click="goTo('https://twitter.com/functionalgut')"
        class="FooterLayout__social"
      >
        <TwitterOutlined
        
        class="FooterLayout__social-icon"
        />
      </div>
      <div
        @click="goTo('https://www.instagram.com/functionalgut/')"
        class="FooterLayout__social"
      >
        <InstagramOutlined
         class="FooterLayout__social-icon"
        />
      </div>
      <div
        @click="goTo('https://www.linkedin.com/company/the-functional-gut-clinic/')"
        class="FooterLayout__social"
      >
        <img
          class="FooterLayout__social-icon FooterLayout__social-linkedin"
          src="@/assets/img/linkedin.svg"
        />
      </div>
    </div>
  </div>

  <div class="FooterLayout__menu">
    <a
      class="FooterLayout__menu-item"
      v-for="(value, key) in menu" :key="key"
      target="_blank"
      :href="value.href"
    >
      {{ key }}
    </a>
  </div>
  
  <!-- <hr class="FooterLayout__hr" /> -->
  
  
</div>
</template>

<script>
import moment from 'moment'
import { TwitterOutlined, InstagramOutlined } from '@ant-design/icons-vue'

export default {
  name: "FooterLayout",
  components: {
    TwitterOutlined,
    InstagramOutlined,
  },
  data() {
    return {
      menu: {
        'FAQs': {
          href: 'https://functionalgutdiagnostics.com/products-and-services/breath-testing/',
        },
        'Terms & conditions': {
          href: 'https://thefunctionalgutclinic.com/terms-and-conditions/',
        },
        'Refunds policy': {
          href: 'https://thefunctionalgutclinic.com/refunds/',
        },
        'Data privacy policy': {
          href: 'https://thefunctionalgutclinic.com/data-privacy-policy/',
        },
        'Website privacy policy': {
          href: 'https://thefunctionalgutclinic.com/website-privacy-policy/',
        },
        'Cookie policy': {
          href: 'https://thefunctionalgutclinic.com/cookie-policy/',
        },
        'Sitemap': {
          href: 'https://thefunctionalgutclinic.com/sitemap/',
        },
      }
    }
  },
  computed: {
    moment: () => moment,
  },
  methods: {
    goTo(link) {
      window.open(link, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.FooterLayout {
  background-color: $background;
  padding: 15px;
  
  &__wrapper_upper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &>div{
      width: 50%;
    }
  }
  &__copy {
    color: $primary10;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
  &__menu {
    columns: 2;
  }
  
  &__menu-item {
    display: block;
    cursor: pointer;
    color: $geekBlue4;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
  }
  
  &__hr {
    height: 0;
    border: 0;
    border-bottom: 1px solid #CFD7E4;
    margin: 20px 0;
  }
  
  &__social-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
    margin-left: 12px;
  }
  
  &__social {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: #80A3CC;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
  
  &__social-icon {
    font-size: 16px;
    color: white;
  }
  
  &__social-linkedin {
    margin-left: 2px;
    margin-bottom: 2px;
  }
}
</style>